<template>
  <slot
    v-bind="{
      ...$attrs,
      id,
      fullButtonClasses,
      isOpen,
      fullDropdownClasses,
      closeOnClick,
      open,
      close,
      toggle,
      disabled,
    }"
  />
</template>

<script>
/* eslint-disable vue/component-api-style */
import { defineStore } from 'vue_features/shared/composables/store_helpers'
import { nextTick, reactive } from 'vue'
import { useNamespacedEventListener } from 'vue_features/shared/composables'

const useGlobalDropdown = defineStore('globalDropdown', () => {
  const state = reactive({
    openId: null,
    closeFn: null,
  })

  const { on: bodyOn, off: bodyOff } = useNamespacedEventListener(document.body)
  const { on: winOn, off: winOff } = useNamespacedEventListener(window)
  const CLICK = 'click.dropdown'
  const RESIZE = 'resize.dropdown'

  function cleanUp() {
    bodyOff(CLICK)
    winOff(RESIZE)
  }

  function closeGlobal() {
    state.closeFn()
    cleanUp()
  }

  function openGlobal(vm) {
    if (state.openId && state.openId !== vm.id) {
      closeGlobal()
    }

    state.openId = vm.id
    state.closeFn = vm.close.bind(vm)

    winOn(RESIZE, vm.updateStyle.bind(vm))
    if (vm.closeOnClick) {
      bodyOn(CLICK, vm.close.bind(vm), { once: true })
    }
  }

  return { state, openGlobal, cleanUp }
})

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    buttonClasses: {
      type: Array,
      required: false,
      default: () => [],
    },
    dropdownClasses: {
      type: Array,
      required: false,
      default: () => [],
    },
    closeOnClick: {
      type: Boolean,
      required: false,
      default: true,
    },
    openDropdown: {
      type: Boolean,
      default: false,
    },
    defaultBtnClass: {
      type: String,
      default: 'btn',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { openGlobal, cleanUp } = useGlobalDropdown()
    return { openGlobal, cleanUp }
  },
  data() {
    return { isOpen: false, flowLeft: false }
  },
  computed: {
    fullDropdownClasses() {
      const classes = ['dropdown-pane', 'vue-dropdown', ...this.dropdownClasses]
      if (this.isOpen) {
        classes.push('is-open')
      }
      if (this.flowLeft) {
        classes.push('flow-left')
      }
      return classes
    },
    fullButtonClasses() {
      return [this.defaultBtnClass, ...this.buttonClasses]
    },
  },
  watch: {
    isOpen(newIsOpen) {
      if (newIsOpen) {
        this.updateStyle()
        this.openGlobal(this)
      }
    },
    openDropdown: {
      immediate: true,
      handler(newOpenDropdown) {
        if (newOpenDropdown) this.open()
      },
    },
  },
  mounted() {
    this.updateStyle()
  },
  beforeUnmount() {
    this.cleanUp()
  },
  methods: {
    close() {
      this.isOpen = false
      this.$emit('dropdown-toggled', this.isOpen)
      this.cleanUp()
    },
    open() {
      this.isOpen = true
      this.$emit('dropdown-toggled', this.isOpen)
    },
    toggle() {
      this.isOpen ? this.close() : this.open()
    },
    updateStyle() {
      const parent = document.getElementById(this.id)
      const buffer = 20
      this.flowLeft = false
      if (parent) {
        nextTick(() => {
          const parentLeft = parent.getBoundingClientRect().left
          this.flowLeft = window.innerWidth < parentLeft + parent.offsetWidth + buffer
        })
      }
    },
  },
}
</script>
