import { reactive, computed } from 'vue'
import { defineStore } from 'vue_features/shared/composables/store_helpers.js'

function hasPermissionsFor(state, role) {
  return state.authorizedRoles[role] || false
}

export const useCurrentUserStore = defineStore('currentUser', () => {
  const state = reactive({
    id: null,
    name: null,
    email: null,
    role: null,
    authorizedRoles: {},
    canEditResource: null,
    hasPassword: null,
    googleClassroomLinked: null,
    impersonated: false,
    gradeLevel: null,
    school: null,
    showStandardsPrefix: true,
    goPeerToken: null,
    goPeerTutorToken: null,
    goPeerEnv: null,
  })

  const isLoggedIn = computed(() => state.id > 0)
  const isGuest = computed(() => state.role === 'anon')
  const isStudent = computed(() => state.role === 'student')
  const isTeacher = computed(() => state.role === 'teacher')
  const authorizedAsAuthor = computed(() => hasPermissionsFor(state, 'author'))
  const authorizedAsTeacher = computed(() => hasPermissionsFor(state, 'teacher'))
  const authorizedAsSiteAdmin = computed(() => hasPermissionsFor(state, 'siteAdmin'))
  const authorizedAsAdmin = computed(() => hasPermissionsFor(state, 'admin'))
  const authorizedAsCustomerAdmin = computed(() => hasPermissionsFor(state, 'multiSiteAdmin'))

  return {
    state,
    isLoggedIn,
    isGuest,
    isStudent,
    isTeacher,
    authorizedAsAuthor,
    authorizedAsTeacher,
    authorizedAsSiteAdmin,
    authorizedAsAdmin,
    authorizedAsCustomerAdmin,
  }
})
